import { youtube_v3 } from "googleapis/build/src/apis/youtube/v3";
import React from "react";
import { Video } from "../../../../models/video";

// Função para converter duração (ISO 8601) para minutos
const durationToMinutes = (duration: string): number => {
  const match = duration.match(/PT(\d+)M(\d+)S/);
  if (match) {
    const minutes = parseInt(match[1], 10);
    const seconds = parseInt(match[2], 10);
    return minutes + seconds / 60;
  }
  return 0;
};

type Props = { video: Video; ytvideo: youtube_v3.Schema$Video };

const PerformanceMetrics: React.FC<Props> = ({ ytvideo }) => {
  // Extrair valores do ytvideo
  const views = parseInt(ytvideo?.statistics?.viewCount || "0", 10);
  const likes = parseInt(ytvideo?.statistics?.likeCount || "0", 10);
  const comments = parseInt(ytvideo?.statistics?.commentCount || "0", 10);
  const duration = durationToMinutes(ytvideo?.contentDetails?.duration || "0");

  // Calcular métricas
  const engagementPerMinute = ((likes + comments) / duration).toFixed(2);
  const engagementRate = (((likes + comments) / views) * 100).toFixed(2);
  // const channelAverageEngagement = "4.8%"; // Exemplo estático (substitua por cálculo real)

  return (
    <div className="py-6 container">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* Views */}
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-textMuted">Views</h3>
          <p className="text-2xl font-bold text-text">
            {views.toLocaleString()}
          </p>
        </div>

        {/* Likes */}
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-textMuted">Likes</h3>
          <p className="text-2xl font-bold text-text">
            {likes.toLocaleString()}
          </p>
        </div>

        {/* Comments */}
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-textMuted">Comments</h3>
          <p className="text-2xl font-bold text-text">
            {comments.toLocaleString()}
          </p>
        </div>

        {/* Engagement per Minute */}
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-textMuted">
            Engagement/min
          </h3>
          <p className="text-2xl font-bold text-text">{engagementPerMinute}</p>
        </div>

        {/* Engagement Rate */}
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-textMuted">
            Engagement Rate
          </h3>
          <p className="text-2xl font-bold text-text">{engagementRate}%</p>
        </div>

        {/* Channel Average Engagement
        <div className="p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-textMuted">Channel Average</h3>
          <p className="text-2xl font-bold text-text">
            {channelAverageEngagement}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default PerformanceMetrics;
