import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import { User } from "../models/user";
import { Video } from "../models/video";

export default class VideoDb extends BaseDb<
  Video,
  { channelId: string; videoId: string }
> {
  _path: string = "gchannels/channelId/videos/videoId";

  getConverter(): FirestoreDataConverter<Video> {
    return {
      fromFirestore: (snapshot, _options) => {
        return Video.fromFirestore(snapshot);
      },
      toFirestore: (video) => {
        return toFirestore(omit(video, ["id"]));
      },
    };
  }
}
