import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { GoogleAuthProvider } from "firebase/auth";
import {
  IconCalendar,
  IconBrandGoogleFilled,
  IconSearch,
  IconMessageDots,
  IconBulb,
  IconRobot,
  IconTrendingUp,
  IconClock,
  IconBrain,
  IconAutomation,
  IconMessages,
  IconEye,
  IconPencil,
  IconUserCircle,
  IconBuildingStore,
  IconSchool,
  IconDeviceGamepad2,
  IconChevronDown,
  IconHelp,
  IconUserOff,
  IconRocket,
} from "@tabler/icons-react";
import CustomButton from "../utils/customButton";
import { signInWithProvider } from "../../contexts/userContext";
import Footer from "../utils/footer";
import { useMediaQuery } from "@mui/material";
import { Stepper, Step, StepLabel, StepContent } from "@mui/material";

export default function InsightGeniePublic() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithProvider(provider);
      navigate("/app/insight-genie");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const steps = [
    {
      label: "Track Competitors",
      content:
        "Add your competitors' channel IDs to monitor subscriber counts, video performance, and engagement trends in real-time.",
      icon: <IconSearch size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Analyze Comments",
      content:
        "View and analyze all comments from your videos and competitors' videos. Gain actionable insights into audience preferences and pain points.",
      icon: <IconMessageDots size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Generate Content Ideas",
      content:
        "Use AI to turn audience feedback into fresh, relevant content ideas tailored to your niche.",
      icon: <IconBulb size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Respond to Comments",
      content:
        "Automatically generate thoughtful responses to comments on your videos or competitors' videos.",
      icon: <IconRobot size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Spot Trends",
      content:
        "Identify trending topics, outliers, and opportunities for new content or collaborations.",
      icon: <IconTrendingUp size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Save Time with Automation",
      content:
        "Engage your audience and competitors' audiences efficiently, freeing up time to focus on creating high-quality videos.",
      icon: <IconClock size={24} className="text-red-500" stroke={1.5} />,
    },
  ];

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom"
      style={{
        backgroundColor: "#0B0B0F",
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
        <a href="/">
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="h-8 cursor-pointer"
          />
        </a>
      </header>
      <main className="text-center mt-8 sm:mt-12 w-full max-w-5xl mx-auto px-4">
        <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6">
          AI-Powered Insights for YouTube Channels
        </h1>
        <p className="text-gray-300 text-lg mb-8">
          InsightGenie is an AI tool designed to help YouTubers analyze
          competitors, gain personalized insights, and engage audiences with
          precision. Discover how to transform comments and channel data into
          actionable strategies that boost CTR, save time, and improve
          engagement.
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <CustomButton
            variant="contained"
            startIcon={<IconCalendar size={isMobile ? 18 : 20} />}
            onClick={() =>
              window.open(
                "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                "_blank"
              )
            }
            color="primary"
            size="large"
          >
            Book a Demo
          </CustomButton>
          <CustomButton
            variant="contained"
            startIcon={<IconBrandGoogleFilled size={isMobile ? 18 : 20} />}
            onClick={handleSignIn}
            size="large"
          >
            Sign in with Google
          </CustomButton>
        </div>
        <div className="mt-12 flex justify-center">
          <img
            src="/images/insight-genie.png"
            className="max-w-full h-auto"
            alt="InsightGenie Dashboard"
          />
        </div>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            How It Works
          </h2>
          <Stepper
            orientation="vertical"
            sx={{
              "& .MuiStepIcon-root": {
                color: "#282828",
                "&.Mui-active, &.Mui-completed": {
                  color: "#FF0000",
                },
              },
              "& .MuiStepIcon-text": {
                fill: "#FFFFFF",
                fontWeight: "bold",
                fontSize: "0.75rem",
              },
              "& .MuiStepLabel-label": {
                fontFamily: "'YouTubeSansRegular', sans-serif",
                fontSize: { xs: "0.875rem", sm: "1rem" },
              },
              "& .MuiStepContent-root": {
                borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            {steps.map((step, index) => (
              <Step active={true} key={step.label}>
                <StepLabel>
                  <span className="text-white font-custom">{step.label}</span>
                </StepLabel>
                <StepContent>
                  <p className="text-gray-300 mb-4 text-left font-custom text-sm sm:text-base">
                    {step.content}
                  </p>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            Why Choose InsightGenie?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "AI-Powered Insights",
                description:
                  "Understand what your audience and competitors' audiences want.",
                icon: (
                  <IconBrain size={32} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Time-Saving Automation",
                description: "Generate replies and ideas in seconds.",
                icon: (
                  <IconAutomation
                    size={32}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Enhanced Engagement",
                description: "Build stronger relationships with your viewers.",
                icon: (
                  <IconMessages
                    size={32}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Competitor Monitoring",
                description:
                  "Gain real-time stats and detailed insights into their performance.",
                icon: (
                  <IconEye size={32} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Content Ideation",
                description: "Create videos based on proven audience demand.",
                icon: (
                  <IconBulb size={32} className="text-red-500" stroke={1.5} />
                ),
              },
            ].map((benefit, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
              >
                <div className="flex justify-center mb-4">{benefit.icon}</div>
                <h3 className="text-lg font-bold mb-2">{benefit.title}</h3>
                <p className="text-gray-400 text-sm">{benefit.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            What Our Users Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                quote:
                  "InsightGenie helped me uncover video topics my competitors overlooked. My channel's growth has been incredible!",
                author: "Sarah L.",
                role: "Lifestyle Creator",
              },
              {
                quote:
                  "I've saved countless hours responding to comments. The AI-generated replies are on point every time.",
                author: "John P.",
                role: "Tech YouTuber",
              },
              {
                quote:
                  "The ability to analyze competitors' channels in-depth has changed my entire strategy.",
                author: "Emma R.",
                role: "Fitness Influencer",
              },
            ].map((testimonial, index) => (
              <div key={index} className="bg-[#1A1A1F] p-6 rounded-lg">
                <p className="text-gray-300 mb-4 italic">
                  "{testimonial.quote}"
                </p>
                <p className="text-white font-bold">{testimonial.author}</p>
                <p className="text-gray-400 text-sm">{testimonial.role}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            Who Can Benefit?
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Content Creators",
                description: "Uncover new video ideas and audience trends.",
                icon: (
                  <IconPencil size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Faceless Channels",
                description:
                  "Engage audiences effectively without showing your face.",
                icon: (
                  <IconUserOff
                    size={24}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Established YouTubers",
                description: "Analyze competitors to refine strategies.",
                icon: (
                  <IconUserCircle
                    size={24}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Beginner YouTubers",
                description: "Kickstart growth with AI-backed tools.",
                icon: (
                  <IconRocket size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Niche Experts",
                description: "Tailor content to meet specific audience needs.",
                icon: (
                  <IconBrain size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Brands and Businesses",
                description:
                  "Leverage audience insights to improve marketing efforts.",
                icon: (
                  <IconBuildingStore
                    size={24}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Educational Channels",
                description:
                  "Create targeted, informative content based on viewer feedback.",
                icon: (
                  <IconSchool size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Review Channels",
                description:
                  "Identify trending products and audience interests.",
                icon: (
                  <IconEye size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Gaming Channels",
                description:
                  "Discover what captivates viewers and adapt your strategy.",
                icon: (
                  <IconDeviceGamepad2
                    size={24}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
              >
                <div className="flex justify-center mb-4">{item.icon}</div>
                <h3 className="font-bold mb-2">{item.title}</h3>
                <p className="text-gray-400 text-sm">{item.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto mb-12">
          <div className="flex items-center gap-3 mb-6 sm:mb-8">
            <IconHelp size={24} className="text-red-500" stroke={1.5} />
            <h2 className="text-xl sm:text-3xl font-bold font-custom">
              Frequently Asked Questions
            </h2>
          </div>
          <div className="space-y-4">
            {[
              {
                question: "Can I monitor multiple competitors?",
                answer:
                  "Yes, you can track multiple competitor channels simultaneously.",
              },
              {
                question: "How does AI generate content ideas?",
                answer:
                  "It analyzes comments from your videos or competitors' videos to identify audience demands and trending topics.",
              },
              {
                question: "Can I customize AI-generated replies?",
                answer:
                  "Absolutely. You can edit and personalize responses before posting them.",
              },
              {
                question: "Is the tool suitable for beginners?",
                answer:
                  "Yes, InsightGenie is user-friendly and perfect for creators at all levels.",
              },
              {
                question: "Does InsightGenie support real-time stats?",
                answer:
                  "Yes, you get real-time updates on competitors' channels and your own performance.",
              },
              {
                question: "What types of insights can I get from comments?",
                answer:
                  "Discover trends, audience preferences, and recurring questions or themes.",
              },
              {
                question: "Can I try the tool before purchasing?",
                answer:
                  "Yes, InsightGenie is currently in beta. Sign up now to try it out!",
              },
            ].map((faq, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] rounded-lg overflow-hidden"
              >
                <button
                  className="w-full p-4 flex justify-between items-center text-left"
                  onClick={() =>
                    setOpenIndex(openIndex === index ? null : index)
                  }
                >
                  <h3 className="text-lg font-medium">{faq.question}</h3>
                  <IconChevronDown
                    size={20}
                    className={`transform transition-transform duration-300 ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                    stroke={1.5}
                  />
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    openIndex === index ? "max-h-48" : "max-h-0"
                  }`}
                >
                  <p className="text-gray-400 p-4 pt-0 text-left">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
