import React, { useState, useEffect, useContext } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import { UserContext } from "../../../contexts/userContext";
import Functions from "../../../callable/functions";
import { Channel } from "../../../models/channel";
import { youtube_v3 } from "googleapis";
import { IconBrandYoutube } from "@tabler/icons-react";
import VideoInsight from "./video/videoInsight";

interface VideoListProps {
  channel: Channel;
}

const VideoList: React.FC<VideoListProps> = ({ channel }) => {
  const user = useContext(UserContext);
  const [videos, setVideos] = useState<youtube_v3.Schema$Video[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedVideo, setSelectedVideo] =
    useState<youtube_v3.Schema$Video | null>(null);

  useEffect(() => {
    const fetchVideos = async () => {
      if (user && channel) {
        setLoading(true);
        try {
          const functions = new Functions();
          const result = await functions.fetchLatestVideos({
            userId: user.id,
            channelId: channel.id,
            maxResults: 20,
          });
          setVideos(result.data.videos);
          if (result.data.videos.length > 0) {
            setSelectedVideo(result.data.videos[0]);
          }
        } catch (error) {
          console.error("Error fetching videos:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchVideos();
  }, [user, channel]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <CircularProgress color="inherit" size={20} />
      </div>
    );
  }

  return (
    <div className="overflow-hidden">
      <div className="overflow-x-auto scrollbar-hide">
        <div className="flex gap-4 mb-8 p-4">
          {videos.map((video) => (
            <Tooltip
              key={video.id}
              title={video.snippet?.title || "Untitled"}
              arrow
            >
              <div
                className={`w-48 cursor-pointer flex-shrink-0 rounded-lg ${
                  selectedVideo?.id === video.id ? "ring-2 ring-red-500" : ""
                }`}
                onClick={() => setSelectedVideo(video)}
              >
                {video.snippet?.thumbnails?.medium?.url ? (
                  <img
                    src={video.snippet.thumbnails.medium.url}
                    className="w-48 h-27 object-cover rounded-lg"
                  />
                ) : (
                  <div className="w-48 h-27 flex items-center justify-center bg-gray-200 rounded-lg">
                    <IconBrandYoutube size={40} className="text-[#FF0000]" />
                  </div>
                )}
                <div className="my-2 px-2 text-sm text-center text-ellipsis overflow-hidden whitespace-nowrap">
                  {video.snippet?.title || "Untitled"}
                </div>
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
      {selectedVideo && (
        <VideoInsight ytvideo={selectedVideo} channel={channel} />
      )}
    </div>
  );
};

export default VideoList;
