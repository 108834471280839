import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { GoogleAuthProvider } from "firebase/auth";
import {
  IconCalendar,
  IconBrandGoogleFilled,
  IconClock,
  IconEdit,
  IconListCheck,
  IconRefresh,
  IconBolt,
  IconMessages,
  IconBuildingStore,
  IconSchool,
  IconDeviceGamepad2,
  IconChevronDown,
  IconHelp,
  IconLink,
  IconPinnedFilled,
} from "@tabler/icons-react";
import CustomButton from "../utils/customButton";
import { signInWithProvider } from "../../contexts/userContext";
import Footer from "../utils/footer";
import { useMediaQuery } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";

export default function CampaignGeniePublic() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithProvider(provider);
      navigate("/app/campaign-genie");
    } catch (error) {
      console.error("Error signing in:", error);
    }
  };

  const steps = [
    {
      label: "Select Videos",
      content:
        "Choose the videos you want to update—filter by top-performing, recent uploads, or specific date ranges.",
      icon: <IconListCheck size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Update Descriptions",
      content:
        "Edit your video descriptions in bulk. Add new links, promotional text, or updated details without opening each video individually.",
      icon: <IconEdit size={24} className="text-red-500" stroke={1.5} />,
    },
    {
      label: "Change Pinned Comments",
      content:
        "Update or replace pinned comments across multiple videos to highlight your latest offers, events, or announcements.",
      icon: (
        <IconPinnedFilled size={24} className="text-red-500" stroke={1.5} />
      ),
    },
    {
      label: "Review and Apply",
      content:
        "Double-check your changes in the preview and apply them to all selected videos instantly.",
      icon: <IconRefresh size={24} className="text-red-500" stroke={1.5} />,
    },
  ];

  return (
    <div
      className="min-h-screen text-white flex flex-col items-center justify-center px-4 py-8 font-custom"
      style={{
        backgroundColor: "#0B0B0F",
        backgroundImage: "url(/images/background-pattern.png)",
        backgroundPosition: "top center",
      }}
    >
      <header className="w-full max-w-5xl flex justify-between items-center px-8 py-4 mx-auto">
        <a href="/">
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="h-8 cursor-pointer"
          />
        </a>
      </header>

      <main className="text-center mt-8 sm:mt-12 w-full max-w-5xl mx-auto px-4">
        <h1 className="text-2xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6">
          Easily Edit and Update Your YouTube Descriptions and Pinned Comments
          in Bulk
        </h1>
        <p className="text-gray-300 text-lg mb-8">
          Simplify the way you manage your YouTube video descriptions and pinned
          comments. With CampaignGenie, update multiple videos at once, saving
          time and keeping your content consistent. Perfect for creators who
          sell courses, promote affiliate links, or want to keep their content
          fresh and relevant.
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <CustomButton
            variant="contained"
            startIcon={<IconCalendar size={isMobile ? 18 : 20} />}
            onClick={() =>
              window.open(
                "https://calendar.app.google/mU2hf2jVRsCs5dBNA",
                "_blank"
              )
            }
            color="primary"
            size="large"
          >
            Book a Demo
          </CustomButton>
          <CustomButton
            variant="contained"
            startIcon={<IconBrandGoogleFilled size={isMobile ? 18 : 20} />}
            onClick={handleSignIn}
            size="large"
          >
            Sign in with Google
          </CustomButton>
        </div>
        <div className="mt-12 flex justify-center">
          <img
            src="/images/campaign-genie.png"
            className="max-w-full h-auto"
            alt="CampaignGenie Dashboard"
          />
        </div>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            How It Works
          </h2>
          <Stepper
            orientation="vertical"
            sx={{
              "& .MuiStepIcon-root": {
                color: "#282828",
                "&.Mui-active, &.Mui-completed": {
                  color: "#FF0000",
                },
              },
              "& .MuiStepIcon-text": {
                fill: "#FFFFFF",
                fontWeight: "bold",
                fontSize: "0.75rem",
              },
              "& .MuiStepLabel-label": {
                fontFamily: "'YouTubeSansRegular', sans-serif",
                fontSize: { xs: "0.875rem", sm: "1rem" },
              },
              "& .MuiStepContent-root": {
                borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            {steps.map((step, index) => (
              <Step active={true} key={step.label}>
                <StepLabel>
                  <span className="text-white font-custom">{step.label}</span>
                </StepLabel>
                <StepContent>
                  <p className="text-gray-300 mb-4 text-left font-custom text-sm sm:text-base">
                    {step.content}
                  </p>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            Why Choose CampaignGenie?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Save Hours of Manual Work",
                description:
                  "Edit descriptions and pinned comments for dozens of videos in one go.",
                icon: (
                  <IconClock size={32} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Keep Content Up-to-Date",
                description:
                  "Quickly add new affiliate links, update course details, or promote fresh offers across your entire channel.",
                icon: (
                  <IconRefresh
                    size={32}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Stay Organized",
                description:
                  "Manage all updates from a single dashboard with clear steps and real-time feedback.",
                icon: (
                  <IconListCheck
                    size={32}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Boost Efficiency",
                description:
                  "Focus on growing your channel instead of repetitive, time-consuming tasks.",
                icon: (
                  <IconBolt size={32} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Improve Viewer Engagement",
                description:
                  "Keep your audience informed and engaged with relevant, updated information in your videos.",
                icon: (
                  <IconMessages
                    size={32}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
            ].map((benefit, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
              >
                <div className="flex justify-center mb-4">{benefit.icon}</div>
                <h3 className="text-lg font-bold mb-2">{benefit.title}</h3>
                <p className="text-gray-400 text-sm">{benefit.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            What Our Users Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                quote:
                  "CampaignGenie has saved me hours of work updating my course links across hundreds of videos!",
                author: "Michael T.",
                role: "Course Creator",
              },
              {
                quote:
                  "The bulk editing feature is a game-changer for managing multiple channels efficiently.",
                author: "Sarah K.",
                role: "Content Manager",
              },
              {
                quote:
                  "I love how easy it is to keep my affiliate links up-to-date across all my videos.",
                author: "David R.",
                role: "Affiliate Marketer",
              },
            ].map((testimonial, index) => (
              <div key={index} className="bg-[#1A1A1F] p-6 rounded-lg">
                <p className="text-gray-300 italic mb-4">
                  "{testimonial.quote}"
                </p>
                <p className="text-white font-bold">{testimonial.author}</p>
                <p className="text-gray-400 text-sm">{testimonial.role}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto">
          <h2 className="text-xl sm:text-3xl font-bold mb-6 sm:mb-8 font-custom">
            Who Can Benefit?
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[
              {
                title: "Course Creators",
                description:
                  "Easily promote new courses or update outdated course links.",
                icon: (
                  <IconSchool size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Affiliate Marketers",
                description:
                  "Add or update affiliate links across your videos in seconds.",
                icon: (
                  <IconLink size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Brands and Businesses",
                description:
                  "Keep your offers, promotions, and call-to-actions current.",
                icon: (
                  <IconBuildingStore
                    size={24}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
              {
                title: "Educational Channels",
                description:
                  "Quickly update references or resources in your video descriptions.",
                icon: (
                  <IconSchool size={24} className="text-red-500" stroke={1.5} />
                ),
              },
              {
                title: "Gaming Channels",
                description:
                  "Promote tournaments, new merch, or collaborative content effortlessly.",
                icon: (
                  <IconDeviceGamepad2
                    size={24}
                    className="text-red-500"
                    stroke={1.5}
                  />
                ),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830] cursor-pointer"
              >
                <div className="flex justify-center mb-4">{item.icon}</div>
                <h3 className="font-bold mb-2">{item.title}</h3>
                <p className="text-gray-400 text-sm">{item.description}</p>
              </div>
            ))}
          </div>
        </section>
        <section className="mt-12 sm:mt-16 w-full mx-auto mb-12">
          <div className="flex items-center gap-3 mb-6 sm:mb-8">
            <IconHelp size={24} className="text-red-500" stroke={1.5} />
            <h2 className="text-xl sm:text-3xl font-bold font-custom">
              Frequently Asked Questions
            </h2>
          </div>
          <div className="space-y-4">
            {[
              {
                question: "Can I update multiple videos at once?",
                answer:
                  "Yes, CampaignGenie allows you to bulk edit descriptions and pinned comments for as many videos as you need.",
              },
              {
                question: "What types of updates can I make?",
                answer:
                  "You can add or update text, links, promotional details, or any information in video descriptions and pinned comments.",
              },
              {
                question: "Is it easy to select specific videos?",
                answer:
                  "Yes, you can filter videos by performance metrics, upload dates, or custom criteria to find exactly what you need.",
              },
              {
                question: "Can I preview changes before applying?",
                answer:
                  "Absolutely! CampaignGenie provides a clear preview so you can check all updates before they go live.",
              },
              {
                question: "Does this work with multiple YouTube channels?",
                answer:
                  "Yes, you can manage updates across multiple channels from one CampaignGenie account.",
              },
              {
                question: "Will this tool help me save time?",
                answer:
                  "Definitely! Instead of editing each video manually, you can make changes to dozens or even hundreds of videos in minutes.",
              },
              {
                question: "Is the tool suitable for beginners?",
                answer:
                  "Yes, CampaignGenie is easy to use and designed for creators of all experience levels.",
              },
              {
                question: "Can I revert changes if needed?",
                answer:
                  "Yes, CampaignGenie keeps a log of your updates, so you can roll back changes if necessary.",
              },
            ].map((faq, index) => (
              <div
                key={index}
                className="bg-[#1A1A1F] rounded-lg overflow-hidden"
              >
                <button
                  className="w-full p-4 flex justify-between items-center text-left"
                  onClick={() =>
                    setOpenIndex(openIndex === index ? null : index)
                  }
                >
                  <h3 className="text-lg font-medium">{faq.question}</h3>
                  <IconChevronDown
                    size={20}
                    className={`transform transition-transform duration-300 ${
                      openIndex === index ? "rotate-180" : ""
                    }`}
                  />
                </button>
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    openIndex === index ? "max-h-48" : "max-h-0"
                  }`}
                >
                  <p className="text-gray-400 p-4 pt-0 text-left">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
