import React from "react";
import { Routes, Route } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import HomeScreen from "./homeScreen";
import AnalyticsScreen from "./analyticsScreen";
import SettingsScreen from "./settingsScreen";
import SideBar from "./sideBar";
import PopMenu from "./popMenu";

export default function InsightGenieApp() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="flex h-screen w-screen overflow-hidden">
      {!isMobile && <SideBar />}
      <div className="flex-1 overflow-x-hidden">
        <PopMenu />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/analytics" element={<AnalyticsScreen />} />
          <Route path="/settings" element={<SettingsScreen />} />
        </Routes>
      </div>
    </div>
  );
}
