import BaseDb from "./baseDb";
import toFirestore from "./toFirestore";
import { FirestoreDataConverter } from "firebase/firestore";
import { omit } from "lodash";
import { User } from "../models/user";

export default class UserDb extends BaseDb<User, { userId: string }> {
  _path: string = "users/userId";

  getConverter(): FirestoreDataConverter<User> {
    return {
      fromFirestore: (snapshot, _options) => {
        const data = snapshot.data();
        return new User({
          id: snapshot.id,
          email: data.email,
          token: data.token,
          channels: data.channels || [],
          credits: data.credits || 0,
          ref: data.ref,
          qualificationData: data.qualificationData || null,
        });
      },
      toFirestore: (user) => {
        return toFirestore(omit(user, ["id"]));
      },
    };
  }
}
