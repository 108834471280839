import { DocumentSnapshot } from "firebase/firestore";

export interface IUser {
  id: string;
  email: string;
  token?: string;
  channels: string[];
  credits: number;
  ref?: string;
  qualificationData?: {
    channelName: string | null;
    subscriberCount: string;
    referralSource: string;
  };
}

export class User implements IUser {
  public id!: string;
  public email!: string;
  public token?: string;
  public channels!: string[];
  public credits!: number;
  public ref?: string;
  public qualificationData?: {
    channelName: string | null;
    subscriberCount: string;
    referralSource: string;
  };

  constructor(param: IUser) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): User {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new User({
      id: doc.id,
      email: data.email,
      token: data.token,
      channels: data.channels || [],
      credits: data.credits || 0,
      ref: data.ref,
      qualificationData: data.qualificationData,
    });
  }

  public toFirestore(): Partial<IUser> {
    return {
      email: this.email,
      token: this.token,
      channels: this.channels,
      credits: this.credits,
      ref: this.ref,
      qualificationData: this.qualificationData,
    };
  }
}
