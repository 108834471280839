// src/components/screens/insight-genie/sideBar.tsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconMessageCircleBolt,
  IconChartLine,
  IconSettings,
  IconLogout,
} from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";
import { UserContext } from "../../../contexts/userContext";
import { Tooltip, Menu, MenuItem } from "@mui/material";

export default function SideBar() {
  const navigate = useNavigate();
  const user = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogoHover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogoLeave = () => {
    setAnchorEl(null);
  };

  const handleProductSwitch = (product: string) => {
    handleLogoLeave();
    navigate(`/app/${product}`);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/insight-genie");
  };

  return (
    <div className="bg-[#0B0B0F] h-screen w-20 flex-none flex flex-col items-center py-6 space-y-6 border-r border-[#282828]">
      <Tooltip title="InsightGenie by YouGenie" placement="right">
        <div
          className="relative"
          onMouseEnter={handleLogoHover}
          onMouseLeave={handleLogoLeave}
        >
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="w-8 h-8 mb-4 cursor-pointer"
            onClick={() => handleNavigation("/app/insight-genie")}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLogoLeave}
            PaperProps={{
              style: {
                backgroundColor: "#1E1E1E",
                color: "white",
              },
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <MenuItem onClick={() => handleProductSwitch("thumb-genie")}>
              ThumbGenie
            </MenuItem>
            <MenuItem onClick={() => handleProductSwitch("campaign-genie")}>
              CampaignGenie
            </MenuItem>
          </Menu>
        </div>
      </Tooltip>
      <Tooltip title="Comments" placement="right">
        <button onClick={() => handleNavigation("/app/insight-genie")}>
          <IconMessageCircleBolt
            size={24}
            strokeWidth={1}
            className="text-white mb-6"
          />
        </button>
      </Tooltip>
      <Tooltip title="Analytics" placement="right">
        <button
          onClick={() => handleNavigation("/app/insight-genie/analytics")}
        >
          <IconChartLine
            size={24}
            strokeWidth={1}
            className="text-white mb-6"
          />
        </button>
      </Tooltip>
      <Tooltip title="Settings" placement="right">
        <button onClick={() => handleNavigation("/app/insight-genie/settings")}>
          <IconSettings size={24} strokeWidth={1} className="text-white mb-6" />
        </button>
      </Tooltip>
      <div className="flex-grow" />
      <Tooltip title="Logout" placement="right">
        <button onClick={handleLogout}>
          <IconLogout size={24} strokeWidth={1} className="text-white" />
        </button>
      </Tooltip>
    </div>
  );
}
