import React, { ReactNode } from "react";
import { max } from "lodash";

interface CustomButtonProps {
  onClick: () => void;
  children: string;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  loadingText?: string;
  variant?: "text" | "outlined" | "contained";
  color?:
    | "white"
    | "default"
    | "primary"
    | "secondary"
    | "tertiary"
    | "yougenie"
    | "error"
    | "warning"
    | "info"
    | "success";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean; // Nova prop
}

const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  disabled,
  startIcon,
  endIcon,
  loading,
  loadingText = "Loading",
  variant = "outlined",
  color = "default",
  size = "medium",
  fullWidth = false, // Valor padrão
}) => {
  // Mapeamento de cores para classes CSS
  const colorClasses = {
    white: {
      contained:
        "bg-white text-slate-800 hover:bg-gray-100 disabled:bg-opacity-50",
      text: "bg-transparent text-default hover:bg-white hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-white hover:bg-gray-100 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    default: {
      contained:
        "bg-default-500 text-white hover:bg-default-600 disabled:bg-opacity-50",
      text: "bg-transparent text-default hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-default-500 hover:bg-default-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    primary: {
      contained:
        "bg-primary-500 text-white hover:bg-primary-600 disabled:bg-opacity-50",
      text: "bg-transparent text-primary hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-primary-500 hover:bg-primary-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    secondary: {
      contained:
        "bg-secondary-500 text-white hover:bg-secondary-600 disabled:bg-opacity-50",
      text: "bg-transparent text-secondary hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-secondary-500 hover:bg-secondary-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    tertiary: {
      contained:
        "bg-tertiary-500 text-white hover:bg-tertiary-600 disabled:bg-opacity-50",
      text: "bg-transparent text-tertiary hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-tertiary-500 hover:bg-tertiary-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    yougenie: {
      contained:
        "bg-yougenie-500 text-white hover:bg-yougenie-600 disabled:bg-opacity-50",
      text: "bg-transparent text-yougenie hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-yougenie-500 hover:bg-yougenie-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    error: {
      contained:
        "bg-error-500 text-white hover:bg-error-600 disabled:bg-opacity-50",
      text: "bg-transparent text-error hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-error-500 hover:bg-error-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    warning: {
      contained:
        "bg-warning-500 text-white hover:bg-warning-600 disabled:bg-opacity-50",
      text: "bg-transparent text-warning hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-warning-500 hover:bg-warning-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    info: {
      contained:
        "bg-info-500 text-white hover:bg-info-600 disabled:bg-opacity-50",
      text: "bg-transparent text-info hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-info-500 hover:bg-info-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
    success: {
      contained:
        "bg-success-500 text-white hover:bg-success-600 disabled:bg-opacity-50",
      text: "bg-transparent text-success hover:bg-slate-500 hover:bg-opacity-20 disabled:text-gray-400",
      outlined:
        "bg-transparent text-white border border-success-500 hover:bg-success-200 hover:bg-opacity-20 disabled:text-gray-400 border-gray-400",
    },
  };

  // Mapeamento de tamanhos para classes CSS
  const sizeClasses = {
    small: "px-3 py-1 text-xs",
    medium: "px-5 py-2 text-sm",
    large: "px-7 py-3 text-base",
  };

  // Classes base do botão
  const baseClasses = `
    font-['YouTubeSansRegular']
    normal-case
    rounded-full
    transition-colors
    duration-300
    ${loading ? "cursor-not-allowed" : ""}
    ${sizeClasses[size]}
    ${fullWidth ? "w-full" : ""} 
  `;

  const width = fullWidth
    ? "100%"
    : max([
        children.length * getFontWidthFactor(size) +
          18 * (!!startIcon ? 1 : 0) +
          18 * (!!endIcon ? 1 : 0),
        loading !== undefined
          ? loadingText.length * getFontWidthFactor(size) + 20
          : 0,
      ]);

  function getFontWidthFactor(size: "small" | "medium" | "large") {
    const fontFactor = 0.6;
    switch (size) {
      case "small":
        return 12 * fontFactor;
      case "large":
        return 16 * fontFactor;
      case "medium":
      default:
        return 14 * fontFactor;
    }
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={`${baseClasses} ${colorClasses[color][variant]}`}
    >
      <div style={{ width }}>
        {loading ? (
          <div className="flex items-center space-x-4 justify-center">
            <div className="overflow-hidden whitespace-nowrap">
              {loadingText}
            </div>
            <div className="flex justify-center items-center space-x-1">
              <div className="h-1 w-1 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
              <div className="h-1 w-1 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
              <div className="h-1 w-1 bg-white rounded-full animate-bounce"></div>
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-center space-x-2">
            {startIcon && <div>{startIcon}</div>}
            <div>{children}</div>
            {endIcon && <div>{endIcon}</div>}
          </div>
        )}
      </div>
    </button>
  );
};

export default CustomButton;
