// src/components/screens/thumb-genie/ImageEditModal.tsx
import React, { useState, useRef, useEffect, useContext } from "react";
import { Dialog, TextField } from "@mui/material";
import CanvasDraw from "react-canvas-draw";
import CustomButton from "../../utils/customButton";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserContext } from "../../../contexts/userContext";

interface ImageEditModalProps {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  onSubmit: (data: { mask: string; prompt: string }) => void;
}

const ImageEditModal: React.FC<ImageEditModalProps> = ({
  open,
  onClose,
  imageUrl,
  onSubmit,
}) => {
  const user = useContext(UserContext);
  const [prompt, setPrompt] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const canvasRef = useRef<CanvasDraw>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = imageUrl;
  }, [imageUrl]);

  const handleSubmit = async () => {
    if (!canvasRef.current) return;
    setUploading(true);

    try {
      // @ts-ignore: Method exists but missing from type definitions
      const maskDataUrl = canvasRef.current.getDataURL(
        "png",
        false,
        "transparent"
      );

      // Create a canvas to modify the image
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();

      await new Promise((resolve) => {
        img.onload = () => {
          canvas.width = img.width;
          canvas.height = img.height;

          // Fill with black background
          if (ctx) {
            ctx.fillStyle = "#000";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Draw the mask image on top
            ctx.drawImage(img, 0, 0);
          }
          resolve(null);
        };
        img.src = maskDataUrl;
      });

      // Get the final image with black background
      const finalMaskDataUrl = canvas.toDataURL("image/png");

      // Convert to blob and upload
      const response = await fetch(finalMaskDataUrl);
      const blob = await response.blob();

      const storage = getStorage();
      const storageRef = ref(
        storage,
        `users/${user?.id}/masks/${Date.now()}.png`
      );

      await uploadBytes(storageRef, blob);
      const maskUrl = await getDownloadURL(storageRef);

      await onSubmit({
        mask: maskUrl,
        prompt,
      });
      onClose();
    } catch (error) {
      console.error("Error uploading mask:", error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="bg-[#1A1A1F] p-6 space-y-6">
        <div className="flex items-center gap-2">
          <h2 className="text-lg font-custom font-bold text-white">
            Edit Image
          </h2>
        </div>

        <div className="relative w-full aspect-video">
          {imageLoaded && (
            <>
              <img
                src={imageUrl}
                alt="Edit Base"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <CanvasDraw
                ref={canvasRef}
                imgSrc={imageUrl}
                brushColor="#fff"
                brushRadius={5}
                canvasWidth={1280}
                canvasHeight={720}
                hideGrid
                backgroundColor="transparent"
                lazyRadius={0}
                style={{
                  position: "absolute",
                  inset: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            </>
          )}
        </div>

        <TextField
          fullWidth
          label="Prompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              color: "white",
              "& fieldset": { borderColor: "#282828" },
            },
            "& .MuiInputLabel-root": { color: "gray" },
          }}
        />

        <div className="flex justify-end space-x-4">
          <CustomButton onClick={onClose} variant="outlined">
            Cancel
          </CustomButton>
          <CustomButton onClick={handleSubmit} disabled={uploading}>
            {uploading ? "Generating..." : "Generate"}
          </CustomButton>
        </div>
      </div>
    </Dialog>
  );
};

export default ImageEditModal;
