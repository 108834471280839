import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { CircularProgress } from "@mui/material";
import CustomButton from "../utils/customButton";
import {
  IconHeading,
  IconAnalyze,
  IconClick,
  IconChevronDown,
  IconHelp,
  IconBrandYoutube,
  IconArrowsShuffle,
  IconSearch,
  IconTrendingUp,
} from "@tabler/icons-react";
import Functions from "../../callable/functions";

export default function TitleGenerator() {
  const [script, setScript] = useState("");
  const [titles, setTitles] = useState<string[]>([]);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const pageTitle =
    "YouTube Title Generator | Free AI-Powered Title Creation Tool";
  const pageDescription =
    "Generate engaging YouTube video titles from your script using AI. Get data-driven title suggestions that increase click-through rates and viewer engagement.";

  const handleGenerate = async () => {
    if (!script.trim()) {
      setError("Please provide a script");
      return;
    }

    setGenerating(true);
    setError(null);
    try {
      const functions = new Functions();
      const result = await functions.generateVideoTitle({
        script: script.trim(),
      });

      if (result.data.success) {
        setTitles(result.data.titles);
      } else {
        setError("Failed to generate titles. Please try again.");
      }
    } catch (error) {
      console.error("Error generating titles:", error);
      setError("An error occurred while generating titles");
    } finally {
      setGenerating(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>

      <div className="min-h-screen bg-[#0B0B0F] text-white font-custom">
        <header className="w-full max-w-5xl flex justify-between items-center px-4 py-4 mx-auto">
          <a href="/">
            <img
              src="/images/logo.svg"
              alt="YouGenie Logo"
              className="h-6 sm:h-8 cursor-pointer"
            />
          </a>
        </header>

        <main className="max-w-5xl mx-auto px-4 py-8">
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            YouTube Title Generator
          </h1>
          <p className="text-gray-400 mb-8">
            Transform your video script into engaging titles that drive clicks
            and views. Our AI analyzes your content to generate
            attention-grabbing titles optimized for YouTube's algorithm.
          </p>

          {/* Main Generation Section */}
          <div className="space-y-6 mb-12">
            <div className="w-full space-y-6">
              <div>
                <label className="block text-sm font-bold mb-2">
                  Video Script
                </label>
                <textarea
                  value={script}
                  onChange={(e) => setScript(e.target.value)}
                  placeholder="Paste your video script here..."
                  rows={12}
                  className="w-full bg-[#1A1A1F] border border-gray-700 rounded-lg p-3 text-white placeholder-gray-500 focus:outline-none focus:border-red-500"
                />
              </div>

              {error && <div className="text-red-500 text-sm">{error}</div>}

              <div className="flex justify-end">
                <CustomButton
                  onClick={handleGenerate}
                  disabled={generating || !script.trim()}
                  startIcon={
                    generating ? (
                      <CircularProgress size={20} />
                    ) : (
                      <IconHeading size={20} />
                    )
                  }
                >
                  {generating ? "Generating..." : "Generate Titles"}
                </CustomButton>
              </div>
            </div>

            {/* Results Section */}
            <div className="w-full">
              <div className="bg-[#1A1A1F] rounded-lg p-6">
                <h2 className="text-xl font-bold mb-4">Generated Titles</h2>
                {generating ? (
                  <div className="flex flex-col items-center justify-center space-y-4 py-12">
                    <CircularProgress color="inherit" size={40} />
                    <p className="text-gray-400">Generating titles...</p>
                  </div>
                ) : titles.length > 0 ? (
                  <div className="space-y-4">
                    {titles.map((title, index) => (
                      <div
                        key={index}
                        className="bg-[#282830] p-4 rounded-lg hover:bg-[#32323a] transition-colors duration-200"
                      >
                        <p className="text-lg">{title}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-12 text-gray-400">
                    <IconBrandYoutube
                      size={48}
                      className="mx-auto mb-4 text-gray-500"
                    />
                    <p>Paste your video script to generate engaging titles</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Features Section */}
          <section className="mt-12">
            <h2 className="text-xl sm:text-3xl font-bold mb-6">
              Why Use Our Title Generator?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                {
                  title: "AI-Powered Analysis",
                  description:
                    "Our AI analyzes your script to create titles that resonate with your content and audience.",
                  icon: (
                    <IconAnalyze
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  title: "CTR Optimization",
                  description:
                    "Get titles designed to maximize click-through rates and viewer engagement.",
                  icon: (
                    <IconClick
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
                {
                  title: "Multiple Variations",
                  description:
                    "Generate multiple title options to A/B test and find what works best.",
                  icon: (
                    <IconArrowsShuffle
                      size={32}
                      className="text-red-500"
                      stroke={1.5}
                    />
                  ),
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-[#1A1A1F] p-6 rounded-lg transform transition-all duration-300 hover:scale-105 hover:bg-[#282830]"
                >
                  <div className="flex justify-center mb-4">{feature.icon}</div>
                  <h3 className="text-lg font-bold mb-2">{feature.title}</h3>
                  <p className="text-gray-400 text-sm">{feature.description}</p>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </>
  );
}
