import React, { useEffect, useState } from "react";
import { Channel } from "../../../../models/channel";
import { map } from "lodash";
import SentimentAnalysis from "./sentimentAnalysis";
import { Video } from "../../../../models/video";
import { onSnapshot } from "firebase/firestore";
import VideoDb from "../../../../db/videoDb";
import { CircularProgress } from "@mui/material";
import PerformanceMetrics from "./performanceMetrics";
import { youtube_v3 } from "googleapis/build/src/apis/youtube/v3";
import ReplyComments from "./replyComments";
import AudienceInsights from "./audienceInsights";

interface CommentThreadProps {
  ytvideo: youtube_v3.Schema$Video;
  channel: Channel;
}

const VideoInsight: React.FC<CommentThreadProps> = ({ ytvideo, channel }) => {
  const [activeTab, setActiveTab] = useState<string>("performance-metrics");
  const [video, setVideo] = useState<Video>();

  useEffect(() => {
    onSnapshot(
      new VideoDb().doc({ channelId: channel.id, videoId: ytvideo.id! }),
      (doc) => {
        if (doc.exists()) {
          setVideo(doc.data());
        }
      }
    );
  }, [channel, ytvideo]);

  const tabs = [
    {
      id: "performance-metrics",
      label: "Performance Metrics",
      content: !!video ? (
        <PerformanceMetrics video={video} ytvideo={ytvideo} />
      ) : (
        <CircularProgress size={20} />
      ),
    },
    {
      id: "sentiment-analysis",
      label: "Sentiment Analysis",
      content: !!video ? (
        <SentimentAnalysis video={video} ytvideo={ytvideo} />
      ) : (
        <CircularProgress size={20} />
      ),
    },
    {
      id: "reply-comments",
      label: "Reply Comments",
      content: !!video ? (
        <ReplyComments channel={channel} ytvideo={ytvideo} />
      ) : (
        <CircularProgress size={20} />
      ),
    },
    {
      id: "audience-insights",
      label: "Audience Insights",
      content: <AudienceInsights channel={channel} ytvideo={ytvideo} />,
    },
  ];
  return (
    <div className="mb-4">
      <nav className="flex space-x-4">
        {map(tabs, (tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-4 py-2 text-sm font-medium rounded-lg transition-colors ${
              activeTab === tab.id
                ? "bg-primary bg-opacity-20 text-primary"
                : "text-gray-300 hover:text-white"
            }`}
          >
            {tab.label}
          </button>
        ))}
      </nav>
      <div className="my-2">
        {tabs.find((tab) => tab.id === activeTab)?.content}
      </div>
    </div>
  );
};

export default VideoInsight;
