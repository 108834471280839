import React, { useContext, useState, useEffect } from "react";
import { Tooltip, TextField, CircularProgress } from "@mui/material";
import {
  IconChevronUp,
  IconChevronDown,
  IconBrandYoutubeFilled,
  IconChecks,
  IconSparkles,
} from "@tabler/icons-react";
import moment from "moment";
import { youtube_v3 } from "googleapis";
import CustomButton from "../../../utils/customButton";
import { UserContext } from "../../../../contexts/userContext";
import Functions from "../../../../callable/functions";
import { Channel } from "../../../../models/channel";

interface CommentThreadProps {
  ytvideo: youtube_v3.Schema$Video;
  channel: Channel;
}

const ReplyComments: React.FC<CommentThreadProps> = ({ ytvideo, channel }) => {
  const user = useContext(UserContext);
  const [commentThreads, setCommentThreads] = useState<
    youtube_v3.Schema$CommentThread[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [replyTexts, setReplyTexts] = useState<{ [key: string]: string }>({});
  const [isReplying, setIsReplying] = useState<{ [key: string]: boolean }>({});
  const [isGeneratingReply, setIsGeneratingReply] = useState<{
    [key: string]: boolean;
  }>({});
  const [showAnimation, setShowAnimation] = useState(false);
  const [expandedThreads, setExpandedThreads] = useState<Set<string>>(
    new Set()
  );

  useEffect(() => {
    const fetchCommentThreads = async () => {
      if (user && ytvideo) {
        setLoading(true);
        try {
          const functions = new Functions();
          const result = await functions.fetchCommentThreads({
            userId: user.id,
            videoId: ytvideo.id!,
            nextPageToken: undefined,
          });
          setCommentThreads(result.data.commentThreads);
        } catch (error) {
          console.error("Error fetching comment threads:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchCommentThreads();
  }, [user, ytvideo]);

  useEffect(() => {
    if (showAnimation) {
      const timer = setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [showAnimation]);

  const handleReply = async (threadId: string) => {
    if (!replyTexts[threadId]?.trim() || !user) return;

    setIsReplying((prev) => ({ ...prev, [threadId]: true }));
    try {
      console.log("Replying to comment thread:", threadId);
      await new Functions().replyCommentThread({
        userId: user.id,
        commentId: threadId,
        channelId: channel.id,
        reply: replyTexts[threadId],
        comment: "",
      });
      // Update the local state to reflect the new reply
      setCommentThreads((prevThreads) =>
        prevThreads.map((thread) =>
          thread.id === threadId
            ? {
                ...thread,
                replies: {
                  ...thread.replies,
                  comments: [
                    ...(thread.replies?.comments || []),
                    {
                      snippet: {
                        textDisplay: replyTexts[threadId],
                        authorDisplayName: channel.title,
                        authorProfileImageUrl: channel.thumbnails?.default?.url,
                        publishedAt: new Date().toISOString(),
                      },
                    },
                  ],
                },
              }
            : thread
        )
      );
      setReplyTexts((prev) => ({ ...prev, [threadId]: "" }));
    } catch (error) {
      console.error("error replying to comment thread:", error);
    } finally {
      setIsReplying((prev) => ({ ...prev, [threadId]: false }));
    }
  };

  const handleGenerateReply = async (threadId: string) => {
    if (!user) return;

    setIsGeneratingReply((prev) => ({ ...prev, [threadId]: true }));
    try {
      const thread = commentThreads.find((t) => t.id === threadId);
      if (!thread || !thread.snippet?.topLevelComment?.snippet) {
        throw new Error("Comment not found");
      }
      const response = await new Functions().generateReply({
        userId: user.id,
        channelId: channel.id,
        comment: thread.snippet.topLevelComment.snippet,
      });
      setReplyTexts((prev) => ({ ...prev, [threadId]: response.data.reply }));
      setShowAnimation(true);
    } catch (error) {
      console.error("error generating reply:", error);
    } finally {
      setIsGeneratingReply((prev) => ({ ...prev, [threadId]: false }));
    }
  };

  const toggleReplies = (threadId: string) => {
    setExpandedThreads((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(threadId)) {
        newSet.delete(threadId);
      } else {
        newSet.add(threadId);
      }
      return newSet;
    });
  };

  const calculateRows = (text: string) => {
    const lines = text.split("\n").length;
    const length = text.length;
    return Math.min(5, Math.max(1, Math.floor(length / 50) + lines));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[50vh]">
        <CircularProgress color="inherit" size={20} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-base font-bold">Reply Comments</h2>
      </div>

      {commentThreads.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-400">No comments found for this video.</p>
        </div>
      ) : (
        commentThreads.map((thread) => (
          <div key={thread.id} className="mb-12 p-4 rounded-lg">
            <div className="flex items-start space-x-4">
              <div className="relative">
                {thread.snippet?.topLevelComment?.snippet
                  ?.authorProfileImageUrl && (
                  <img
                    src={
                      thread.snippet.topLevelComment.snippet
                        .authorProfileImageUrl
                    }
                    alt={
                      thread.snippet.topLevelComment.snippet
                        .authorDisplayName || "Author"
                    }
                    className="w-10 h-10 rounded-full"
                  />
                )}
                {ytvideo.id && (
                  <Tooltip
                    title={
                      <div
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                          height: "auto",
                        }}
                      >
                        <iframe
                          width="100%"
                          height="auto"
                          src={`https://www.youtube.com/embed/${ytvideo.id}`}
                          title={`https://www.youtube.com/embed/${ytvideo.id}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          style={{ borderRadius: "8px" }}
                        ></iframe>
                      </div>
                    }
                    placement="top"
                  >
                    <div className="flex justify-center mt-4">
                      <IconBrandYoutubeFilled size={16} />
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className="flex-1">
                <div className="flex items-center">
                  <h4 className="text-base font-custom font-bold flex-1">
                    {thread.snippet?.topLevelComment?.snippet
                      ?.authorDisplayName || "Unknown Author"}
                    <span className="text-xs font-bold text-gray-500 ml-2">
                      {thread.snippet?.topLevelComment?.snippet?.publishedAt
                        ? `@ ${moment(
                            thread.snippet.topLevelComment.snippet.publishedAt
                          ).format("DD/MM/YY")}`
                        : ""}
                    </span>
                  </h4>
                  {thread.replies?.comments &&
                    thread.replies.comments.length > 0 && (
                      <div className="flex items-center space-x-2">
                        {thread.replies.comments.some(
                          (reply) =>
                            reply.snippet?.authorChannelId?.value &&
                            user?.channels.includes(
                              reply.snippet.authorChannelId.value
                            )
                        ) && (
                          <Tooltip title="You've replied to this comment">
                            <IconChecks size={20} className="text-green-500" />
                          </Tooltip>
                        )}
                      </div>
                    )}
                </div>
                <p className="mt-2 text-base font-custom">
                  {thread.snippet?.topLevelComment?.snippet?.textDisplay ||
                    "No content"}
                </p>
              </div>
            </div>
            <div className="mt-2 mb-4 pl-12 flex items-center space-x-2">
              <TextField
                fullWidth
                variant="standard"
                placeholder="Add a reply"
                value={replyTexts[thread.id!] || ""}
                onChange={(e) =>
                  setReplyTexts((prev) => ({
                    ...prev,
                    [thread.id!]: e.target.value,
                  }))
                }
                multiline
                rows={calculateRows(replyTexts[thread.id!] || "")}
                InputProps={{
                  style: {
                    fontFamily: "'YouTubeSansRegular', sans-serif",
                    color: "white",
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#282828",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#282828",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#282828",
                  },
                }}
                className={
                  showAnimation
                    ? "transition-opacity duration-500 opacity-100"
                    : ""
                }
              />
              <CustomButton
                onClick={() => handleGenerateReply(thread.id!)}
                disabled={isGeneratingReply[thread.id!]}
                loading={isGeneratingReply[thread.id!]}
                loadingText="Generating"
                endIcon={<IconSparkles size={16} />}
              >
                Generate
              </CustomButton>
              <CustomButton
                onClick={() => handleReply(thread.id!)}
                disabled={isReplying[thread.id!]}
                loading={isReplying[thread.id!]}
                loadingText="Replying"
              >
                Reply
              </CustomButton>
            </div>
            {thread.replies?.comments && thread.replies.comments.length > 0 && (
              <div className="pl-12 mt-1">
                <div
                  className="flex items-center space-x-2 cursor-pointer text-blue-500 hover:text-blue-300"
                  onClick={() => toggleReplies(thread.id!)}
                >
                  {expandedThreads.has(thread.id!) ? (
                    <IconChevronUp size={20} />
                  ) : (
                    <IconChevronDown size={20} />
                  )}
                  <span className="text-sm font-bold">{`${thread.replies.comments.length} replies`}</span>
                </div>
                {expandedThreads.has(thread.id!) && (
                  <div className="mt-8 space-y-6">
                    {thread.replies.comments.map((reply) => (
                      <div key={reply.id}>
                        <div className="flex items-start space-x-4">
                          {reply.snippet?.authorProfileImageUrl && (
                            <img
                              src={reply.snippet.authorProfileImageUrl}
                              alt={reply.snippet?.authorDisplayName || "Author"}
                              className="w-8 h-8 rounded-full"
                            />
                          )}
                          <div className="flex-1">
                            <div className="flex justify-between items-center">
                              <h5 className="text-base font-custom font-bold">
                                {reply.snippet?.authorDisplayName ||
                                  "Unknown Author"}
                                <span className="text-xs font-bold text-gray-500 ml-2">
                                  {reply.snippet?.publishedAt
                                    ? `@ ${moment(
                                        reply.snippet.publishedAt
                                      ).format("DD/MM/YY")}`
                                    : ""}
                                </span>
                              </h5>
                            </div>
                            <p className="mt-1 text-base font-custom">
                              {reply.snippet?.textDisplay || "No content"}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ReplyComments;
