import { DocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";

export interface IVideo {
  id: string;
  title: string;
  description: string;
  publishedAt: Moment;
  thumbnails: {
    default?: { url: string; width: number; height: number };
    medium?: { url: string; width: number; height: number };
    high?: { url: string; width: number; height: number };
  };
  lastUpdated: Moment;
  viewCount: number;
  likeCount: number;
  commentCount: number;
  isShort: boolean;
  tags: string[];
  sentiment?: {
    percentages: {
      positive: number;
      negative: number;
      neutral: number;
    };
    main_sentiments: {
      positive: string[];
      neutral: string[];
      negative: string[];
    };
  };
}

export class Video implements IVideo {
  public id!: string;
  public title!: string;
  public description!: string;
  public publishedAt!: Moment;
  public thumbnails!: {
    default?: { url: string; width: number; height: number };
    medium?: { url: string; width: number; height: number };
    high?: { url: string; width: number; height: number };
  };
  public lastUpdated!: Moment;
  public viewCount!: number;
  public likeCount!: number;
  public commentCount!: number;
  public isShort!: boolean;
  public tags!: string[];
  public sentiment?: {
    percentages: {
      positive: number;
      negative: number;
      neutral: number;
    };
    main_sentiments: {
      positive: string[];
      neutral: string[];
      negative: string[];
    };
  };

  constructor(param: IVideo) {
    Object.assign(this, param);
  }

  public static fromFirestore(doc: DocumentSnapshot): Video {
    const data = doc.data();
    if (!data) throw new Error("document data is undefined");
    return new Video({
      id: doc.id,
      title: data.title,
      description: data.description,
      publishedAt: moment(data.publishedAt.toDate()),
      thumbnails: data.thumbnails,
      lastUpdated: moment(data.lastUpdated.toDate()),
      viewCount: data.viewCount,
      likeCount: data.likeCount,
      commentCount: data.commentCount,
      isShort: data.isShort,
      tags: data.tags || [],
      sentiment: data.sentiment,
    });
  }
}
