import React, { useState } from "react";
import { IconSparkles } from "@tabler/icons-react";
import { youtube_v3 } from "googleapis";
import CustomButton from "../../../utils/customButton";
import Functions from "../../../../callable/functions";
import { Channel } from "../../../../models/channel";
import { trackEvent } from "../../../../utils/analytics";

interface CommentThreadProps {
  ytvideo: youtube_v3.Schema$Video;
  channel: Channel;
}

const AudienceInsights: React.FC<CommentThreadProps> = ({
  ytvideo,
  channel,
}) => {
  const [prompt, setPrompt] = useState(
    "Brainstorm 3 video ideas based on the comments — what did they love or ask for?"
  );
  const [asking, setAsking] = useState(false);
  const [response, setResponse] = useState("");

  const handleAskInsightGenie = async () => {
    if (!ytvideo || !prompt) return;
    setAsking(true);
    try {
      const result = await new Functions().analyzeVideoComments({
        videoId: ytvideo.id!,
        prompt: prompt,
      });
      setResponse(result.data.analysis);
      trackEvent("comments_analyzed");
    } catch (error) {
      console.error("Error analyzing comments:", error);
    } finally {
      setAsking(false);
    }
  };

  return (
    <div className="w-full flex flex-col items-stretch justify-center space-y-4">
      <h2 className="text-base font-bold">Audience Insights</h2>

      <div className="flex flex-row items-center space-x-2">
        {/* Campo de texto preenchível */}
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Digite sua pergunta..."
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500 bg-background"
        />

        {/* Botão "Ask InsightGenie" */}
        <CustomButton
          onClick={handleAskInsightGenie}
          variant="outlined"
          endIcon={<IconSparkles size={16} />}
          loading={asking}
        >
          Ask InsightGenie
        </CustomButton>
      </div>

      {/* Campo de texto com a resposta */}
      <div className="mt-6 rounded-lg">
        <p className="text-textMuted">
          {response || "Discover what your audience is saying..."}
        </p>
      </div>
    </div>
  );
};

export default AudienceInsights;
