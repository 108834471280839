import { youtube_v3 } from "googleapis";
import { Video } from "../../../../models/video";
import { range } from "lodash";
import CustomButton from "../../../utils/customButton";
import { IconSparkles } from "@tabler/icons-react";
import Functions from "../../../../callable/functions";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";

type Props = { video: Video; ytvideo: youtube_v3.Schema$Video };

export default function SentimentAnalysis({ video, ytvideo }: Props) {
  return (
    <>
      <h3 className="text-base font-bold mb-2">Sentiment Distribution:</h3>
      <div className="flex w-full h-6 rounded-lg overflow-hidden mb-4">
        {!!video.sentiment ? (
          <>
            <div
              key={1}
              className="h-full bg-success"
              style={{
                width: `${video.sentiment.percentages.positive * 100}%`,
              }}
            >
              <span className="text-white font-bold text-xs ml-2">
                Positive - {video.sentiment.percentages.positive * 100}%
              </span>
            </div>
            <div
              key={2}
              className="h-full bg-info"
              style={{
                width: `${video.sentiment.percentages.neutral * 100}%`,
              }}
            >
              <span className="text-white font-bold text-xs ml-2">
                Neutral - {video.sentiment.percentages.neutral * 100}%
              </span>
            </div>
            <div
              key={3}
              className="h-full bg-error"
              style={{
                width: `${video.sentiment.percentages.negative * 100}%`,
              }}
            >
              <span className="text-white font-bold text-xs ml-2">
                Negative - {video.sentiment.percentages.negative * 100}%
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="animate-pulse w-full h-full bg-gray-800" />
          </>
        )}
      </div>
      <h3 className="text-base font-bold mb-2">Main Sentiments:</h3>
      <div className="flex justify-between mb-4 space-x-6">
        <div className="w-1/3 py-2">
          {!!video.sentiment ? (
            <ul className="space-y-1">
              {video.sentiment.main_sentiments.positive.map(
                (sentiment, index) => (
                  <li key={index}>
                    <div className="inline-block rounded-full bg-success/[.2] py-0.5 px-2.5 text-sm text-white transition-all">
                      {sentiment}
                    </div>
                  </li>
                )
              )}
            </ul>
          ) : (
            <ul className="animate-pulse">
              {range(3).map((_, index) => (
                <li key={index}>
                  <div className="bg-gray-800 w-full h-4 mb-2 rounded-lg" />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-1/3 py-2">
          {!!video.sentiment ? (
            <ul className="space-y-1">
              {video.sentiment.main_sentiments.neutral.map(
                (sentiment, index) => (
                  <li key={index}>
                    <div className="inline-block rounded-full bg-info/[.2] py-0.5 px-2.5 text-sm text-white transition-all">
                      {sentiment}
                    </div>
                  </li>
                )
              )}
            </ul>
          ) : (
            <ul className="animate-pulse">
              {range(3).map((_, index) => (
                <li key={index}>
                  <div className="bg-gray-800 w-full h-4 mb-2 rounded-lg" />
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="w-1/3 py-2">
          {!!video.sentiment ? (
            <ul className="space-y-1">
              {video.sentiment.main_sentiments.negative.map(
                (sentiment, index) => (
                  <li key={index}>
                    <div className="inline-block rounded-full bg-error/[.2] py-0.5 px-2.5 text-sm text-white transition-all">
                      {sentiment}
                    </div>
                  </li>
                )
              )}
            </ul>
          ) : (
            <ul className="animate-pulse">
              {range(3).map((_, index) => (
                <li key={index}>
                  <div className="bg-gray-800 w-full h-4 mb-2 rounded-lg" />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <CustomButton
        // loading
        onClick={async () => {
          await new Functions()
            .analyzeCommentsSentiment({
              videoId: ytvideo.id!,
              channelId: ytvideo.snippet!.channelId!,
            })
            .catch((error) => {
              console.error(error);
            });
        }}
        endIcon={<IconSparkles size={16} />}
      >
        Run Sentiment Analysis
      </CustomButton>
    </>
  );
}
