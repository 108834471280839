import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useMediaQuery, useTheme } from "@mui/material";
import SideBar from "./sideBar";
import PopMenu from "./popMenu";
import ModelsScreen from "./modelsScreen";
import CreditsScreen from "./creditsScreen";
import PipelineScreen from "./pipelineScreen";
import {
  UserContext,
  updateUserQualification,
} from "../../../contexts/userContext";
import QualificationPopup, {
  QualificationData,
} from "../../utils/qualificationPopup";

export default function ThumbGenieApp() {
  const navigate = useNavigate();
  const auth = getAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const user = useContext(UserContext);
  const [showQualification, setShowQualification] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate("/thumb-genie");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [auth, navigate]);

  useEffect(() => {
    if (user && !user.qualificationData) {
      setShowQualification(true);
    } else {
      setShowQualification(false);
    }
  }, [user?.qualificationData]);

  const handleQualificationSubmit = async (data: QualificationData) => {
    if (user) {
      await updateUserQualification(user.id, data);
      setShowQualification(false);
    }
  };

  return (
    <div className="flex">
      {!isMobile && <SideBar />}
      <div className="flex-1">
        <PopMenu />
        <QualificationPopup
          open={showQualification}
          email={user?.email || ""}
          onClose={() => setShowQualification(false)}
          onSubmit={handleQualificationSubmit}
        />
        <Routes>
          <Route path="/" element={<PipelineScreen />} />
          <Route path="/models" element={<ModelsScreen />} />
          <Route path="/credits" element={<CreditsScreen />} />
          <Route path="*" element={<PipelineScreen />} />
        </Routes>
      </div>
    </div>
  );
}
