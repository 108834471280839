import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconMenu,
  IconX,
  IconLogout,
  IconCpu,
  IconPhotoBolt,
  IconCoins,
  IconPhotoEdit,
} from "@tabler/icons-react";
import { getAuth, signOut } from "firebase/auth";
import { UserContext } from "../../../contexts/userContext";

export default function PopMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleHome = () => {
    navigate("/app/thumb-genie");
    setIsOpen(false);
  };

  const handleModels = () => {
    navigate("/app/thumb-genie/models");
    setIsOpen(false);
  };

  const handleCredits = () => {
    navigate("/app/thumb-genie/credits");
    setIsOpen(false);
  };

  const handleLogout = () => {
    signOut(getAuth());
    navigate("/thumb-genie");
    setIsOpen(false);
  };

  return (
    <div className="md:hidden relative" style={{ backgroundColor: "#0B0B0F" }}>
      <button onClick={toggleMenu} className="text-white p-4">
        {isOpen ? <IconX size={20} /> : <IconMenu size={20} />}
      </button>
      {isOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex flex-col items-center py-6 space-y-8 z-50"
          style={{ backgroundColor: "#0B0B0F" }}
        >
          <img
            src="/images/logo.svg"
            alt="YouGenie Logo"
            className="w-8 h-8 mb-4"
          />
          <button onClick={handleHome} className="flex flex-col items-center">
            <IconPhotoBolt size={24} strokeWidth={1} className="text-white" />
          </button>
          <button
            onClick={() => window.open("/thumbnail-editor", "_blank")}
            className="flex flex-col items-center"
          >
            <IconPhotoEdit size={24} strokeWidth={1} className="text-white" />
          </button>
          <button onClick={handleModels} className="flex flex-col items-center">
            <IconCpu size={24} strokeWidth={1} className="text-white" />
          </button>
          <button
            onClick={handleCredits}
            className="flex flex-col items-center"
          >
            <div className="relative">
              <IconCoins size={24} strokeWidth={1} className="text-white" />
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-[10px] rounded-full min-w-[16px] h-4 flex items-center justify-center px-1">
                {user?.credits && user.credits > 99
                  ? "99+"
                  : user?.credits || 0}
              </span>
            </div>
          </button>
          <button onClick={handleLogout} className="flex flex-col items-center">
            <IconLogout size={24} strokeWidth={1} className="text-white" />
          </button>
        </div>
      )}
    </div>
  );
}
